<template>
  <div :class="{'actions-main-header__search header-search search-div': true, 'active': active}">
    <div class="search-row" @click="openModal">
      <div class="header-search__button">
        <i class="bx bx-search"></i>
      </div>
      <p class="header-search__input">
        {{ $t('search.search') }}
      </p>
      <button class="header-search__button header-search__button_filters" :text-tooltip="$t('search.use_to_trigger')" flow="bottom">
        /
      </button>
    </div>
    <div :class="{'search-area': true, 'active': active}">
      <div class="input-area">
        <i class="bx bx-search icon-search"></i>
        <input ref="inputEl" v-model="filter.search" type="text" :placeholder="$t('search.search_placeholder')" @input="searchProjects">
        <i class="bx bx-x icon-close" @click="closeModal"></i>
      </div>
      <div class="search-list">
        <div
          :class="{'list': true, 'active': !loading &&
            trendings.length > 0 &&
            filter.search.length === 0 &&
            searches.exchanges.length === 0 &&
            searches.coins.length === 0 &&
            searches.defi.length === 0
          }"
        >
          <p class="header-desc">
            {{ $t('main.trending') }} 🔥
          </p>
          <div class="flex-list list-area">
            <div
              v-for="(project, ind) in trendings"
              :key="ind"
              :to="localePath('/' + $getProjectCategory(project.type) + '/' + project.slug)"
              class="list-item"
              @click="saveRecent(project)"
            >
              <div class="text-data">
                <div class="image">
                  <nuxt-img
                    :src="$getImage(project.project_image)"
                    :alt="project.title + ' ' + project.token"
                    :title="project.title"
                    :quality="80"
                    placeholder
                    :lazy="true"
                  />
                </div>
                <div class="title">
                  <p>
                    {{ project.title }}
                  </p>
                  <p class="symbol">
                    {{ project.token !== undefined && project.token !== null ? project.token : '' }}
                  </p>
                </div>
              </div>
              <div class="rating">
                {{ project.rank !== undefined && project.rank !== null && 9999 > project.rank ? '#' + project.rank : '' }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loading && totals.coins > 0" class="list active">
          <p class="header-desc">
            {{ $t('common.coins') }}
          </p>
          <div class="flex-list list-area">
            <div
              v-for="(project, ind) in coinsShortList"
              :key="ind"
              :to="localePath('/' + $getProjectCategory(project.type) + '/' + project.slug)"
              class="list-item"
              @click="saveRecent(project)"
            >
              <div class="text-data">
                <div class="image">
                  <nuxt-img
                    :src="$getImage(project.project_image)"
                    :alt="project.title + ' ' + project.token"
                    :title="project.title"
                    :quality="80"
                    placeholder
                    :lazy="true"
                  />
                </div>
                <div class="title">
                  <p>
                    {{ project.title }}
                  </p>
                  <p class="symbol">
                    {{ project.token !== undefined && project.token !== null ? project.token : '' }}
                  </p>
                </div>
              </div>
              <div class="rating">
                {{ project.rank !== undefined && project.rank !== null && 9999 > project.rank ? '#' + project.rank : '' }}
              </div>
            </div>
            <div v-if="!reveals.coins && totals.coins > filter.preview" class="reveal-search" @click="changeReveal('coins')">
              <p>
                {{ $t('search.see_all_results') }} ({{ totals.coins > filter.step ? filter.step : totals.coins }})
              </p>
            </div>
          </div>
        </div>
        <div v-if="!loading && totals.defi > 0" class="list active">
          <p class="header-desc">
            {{ $t('common.defi') }}
          </p>
          <div class="flex-list list-area">
            <div
              v-for="(project, ind) in defiShortList"
              :key="ind"
              :to="localePath('/' + $getProjectCategory(project.type) + '/' + project.slug)"
              class="list-item"
              @click="saveRecent(project)"
            >
              <div class="text-data">
                <div class="image">
                  <nuxt-img
                    :src="$getImage(project.project_image)"
                    :alt="project.title + ' ' + project.token"
                    :title="project.title"
                    :quality="80"
                    placeholder
                    :lazy="true"
                  />
                </div>
                <div class="title">
                  <p>
                    {{ project.title }}
                  </p>
                  <p class="symbol">
                    {{ project.token !== undefined && project.token !== null ? project.token : '' }}
                  </p>
                </div>
              </div>
              <div class="rating">
                {{ project.rank !== undefined && project.rank !== null && 9999 > project.rank ? '#' + project.rank : '' }}
              </div>
            </div>
            <div v-if="!reveals.defi && totals.defi > filter.preview" class="reveal-search" @click="changeReveal('defi')">
              <p>
                {{ $t('search.see_all_results') }} ({{ totals.defi > filter.step ? filter.step : totals.defi }})
              </p>
            </div>
          </div>
        </div>
        <div v-if="!loading && totals.exchanges > 0" class="list active">
          <p class="header-desc">
            {{ $t('common.exchanges') }}
          </p>
          <div class="flex-list list-area">
            <div
              v-for="(project, ind) in exchangesShortList"
              :key="ind"
              :to="localePath('/' + $getProjectCategory(project.type) + '/' + project.slug)"
              class="list-item"
              @click="saveRecent(project)"
            >
              <div class="text-data">
                <div class="image">
                  <nuxt-img
                    :src="$getImage(project.project_image)"
                    :alt="project.title + ' ' + project.token"
                    :title="project.title"
                    :quality="80"
                    placeholder
                    :lazy="true"
                  />
                </div>
                <div class="title">
                  <p>
                    {{ project.title }}
                  </p>
                  <p class="symbol">
                  </p>
                </div>
              </div>
              <div class="rating">
                {{ project.rank !== undefined && project.rank !== null && 9999 > project.rank ? '#' + project.rank : '' }}
              </div>
            </div>
            <div v-if="!reveals.exchanges && totals.exchanges > filter.preview" class="reveal-search" @click="changeReveal('exchanges')">
              <p>
                {{ $t('search.see_all_results') }} ({{ totals.exchanges > filter.step ? filter.step : totals.exchanges }})
              </p>
            </div>
          </div>
        </div>
        <div :class="{'list empty-list': true, 'active': !loading && emptyResult}">
          <div class="search-icon-area">
            <div class="icon-circle">
              <i class="bx bx-search"></i>
            </div>
          </div>
          <div class="h5">
            {{ $t('search.no_results_for') }}
            "{{ filter.search }}"
          </div>
          <div class="h6">
            {{ $t('search.couldnt_find') }}
          </div>
          <div class="h6">
            {{ $t('search.try_again') }}
          </div>
        </div>
        <div :class="{'list empty-list': true, 'active': loading}">
          <div class="search-icon-area">
            <div class="icon-circle">
              <i class="bx bx-loader-alt loading"></i>
            </div>
          </div>
          <div class="h5">
            {{ $t('common.loading') }}
          </div>
        </div>
        <div :class="{'list': true, 'active': recentSearches.length > 0}">
          <p class="header-desc">
            {{ $t('search.recent_searches') }}
          </p>
          <div class="flex-list searches-area">
            <nuxt-link
              v-for="(link, ind) in recentSearches"
              :key="ind"
              :to="localePath('/' + $getProjectCategory(link.type) + '/' + link.slug)"
              class="recent-search"
            >
              <div class="image">
                <nuxt-img
                  :src="$getImage(link.project_image)"
                  :alt="link.title + ' ' + link.token"
                  :title="link.title"
                  :quality="80"
                  placeholder
                  :lazy="true"
                />
              </div>
              <div class="title">
                <p>
                  {{ link.title }}
                </p>
                <p class="symbol">
                  {{ link.token !== undefined && link.token !== null && link.type !== 'exchange' ? link.token : '' }}
                </p>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="search-footer">
        <div class="hint-area">
          <div class="hotkey-style">
            {{ $t('common.esc') }}
          </div>
          <div class="title">
            {{ $t('search.to_close') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SearchPanelComponent',
  props: {
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      recentSearches: [],
      searchTimeout: null,
      timeoutTime: 600,
      filter: {
        search: '',
        preview: 5,
        step: 50,
        saves: 6
      },
      totals: {
        coins: 0,
        defi: 0,
        exchanges: 0
      },
      reveals: {
        coins: false,
        defi: false,
        exchanges: false
      },
      emptyResult: false,
      loading: false,
      trendingLoaded: false
    }
  },
  computed: {
    ...mapGetters('projects', [
      'searches',
      'trendings'
    ]),
    coinsShortList() {
      return !this.reveals.coins ? this.searches.coins.slice(0, this.filter.preview) : this.searches.coins
    },
    defiShortList() {
      return !this.reveals.defi ? this.searches.defi.slice(0, this.filter.preview) : this.searches.defi
    },
    exchangesShortList() {
      return !this.reveals.exchanges ? this.searches.exchanges.slice(0, this.filter.preview) : this.searches.exchanges
    }
  },
  watch: {
    active(to) {
      if (to === true && this.$refs.inputEl !== undefined) {
        if (!this.trendingLoaded && this.trendings.length === 0) {
          this.fetchTrendings({
            step: 5
          })
          this.trendingLoaded = true
        }
        setTimeout(() => {
          this.$refs.inputEl.focus()
        }, 150)
      }
    }
  },
  mounted() {
    this.checkCookies()
    window.addEventListener('keydown', this.keyboardHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyboardHandler)
  },
  methods: {
    ...mapMutations('projects', [
      'unsetSearches'
    ]),
    ...mapActions('projects', [
      'fetchGlobalSearch',
      'fetchTrendings'
    ]),
    keyboardHandler(evt) {
      const keycode = (evt.keyCode ? evt.keyCode : evt.which)
      if (keycode === 191 && this.active === false) {
        evt.preventDefault()
        this.$emit('toggle')
      }
      if (keycode === 27 && this.active === true) {
        evt.preventDefault()
        this.closeModal()
      }
    },
    searchProjects() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        await this.loadProjects()
      }, this.timeoutTime)
    },
    async loadProjects() {
      this.emptyResult = false
      this.loading = true
      this.unsetSearches()
      await this.fetchGlobalSearch({
        search: this.filter.search
      }).then(() => {
        this.reveals = {
          coins: false,
          defi: false,
          exchanges: false
        }
        this.totals.coins = this.searches.coins.length
        this.totals.defi = this.searches.defi.length
        this.totals.exchanges = this.searches.exchanges.length
        this.loading = false
        if (this.totals.coins === 0 && this.totals.defi === 0 && this.totals.exchanges === 0) {
          this.emptyResult = true
        }
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    },
    changeReveal(type) {
      this.reveals[type] = true
    },
    closeModal() {
      this.unsetSearches()
      this.filter.search = ''
      this.totals = {
        coins: 0,
        defi: 0,
        exchanges: 0
      }
      this.reveals = {
        coins: false,
        defi: false,
        exchanges: false
      }
      this.emptyResult = false
      this.loading = false
      if (this.active === true) {
        this.$emit('toggle')
      }
    },
    openModal() {
      if (this.active === false) {
        this.$emit('toggle')
      }
    },
    checkCookies() {
      const recentSearches = this.$cookies.get(this.$config.searchesCookieName)
      if (recentSearches !== undefined) {
        this.recentSearches = recentSearches
      }
    },
    saveRecent(project) {
      this.closeModal()
      const cookieToSave = {
        title: project.title,
        token: project.token,
        type: project.type,
        rank: project.rank,
        slug: project.slug,
        project_image: project.project_image
      }
      const recentSearches = this.$cookies.get(this.$config.searchesCookieName)
      if (recentSearches !== undefined && recentSearches !== null && recentSearches.length > 0) {
        try {
          const issetProject = recentSearches.find(item => item.slug === project.slug)
          if (issetProject === undefined) {
            if (recentSearches.length >= this.filter.saves) {
              recentSearches.pop()
            }
            recentSearches.unshift(cookieToSave)
            this.$cookies.set(this.$config.searchesCookieName, recentSearches)
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.$cookies.set(this.$config.searchesCookieName, [cookieToSave])
      }
      this.$router.push({
        path: this.localePath('/' + this.$getProjectCategory(project.type) + '/' + project.slug)
      })
      this.checkCookies()
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/components/search.css');
</style>
