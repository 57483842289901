var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "actions-main-header__search header-search search-div": true,
        active: _vm.active,
      },
    },
    [
      _c("div", { staticClass: "search-row", on: { click: _vm.openModal } }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "header-search__input" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("search.search")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "header-search__button header-search__button_filters",
            attrs: {
              "text-tooltip": _vm.$t("search.use_to_trigger"),
              flow: "bottom",
            },
          },
          [_vm._v("\n      /\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: { "search-area": true, active: _vm.active } }, [
        _c("div", { staticClass: "input-area" }, [
          _c("i", { staticClass: "bx bx-search icon-search" }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filter.search,
                expression: "filter.search",
              },
            ],
            ref: "inputEl",
            attrs: {
              type: "text",
              placeholder: _vm.$t("search.search_placeholder"),
            },
            domProps: { value: _vm.filter.search },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.filter, "search", $event.target.value)
                },
                _vm.searchProjects,
              ],
            },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "bx bx-x icon-close",
            on: { click: _vm.closeModal },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-list" }, [
          _c(
            "div",
            {
              class: {
                list: true,
                active:
                  !_vm.loading &&
                  _vm.trendings.length > 0 &&
                  _vm.filter.search.length === 0 &&
                  _vm.searches.exchanges.length === 0 &&
                  _vm.searches.coins.length === 0 &&
                  _vm.searches.defi.length === 0,
              },
            },
            [
              _c("p", { staticClass: "header-desc" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("main.trending")) +
                    " 🔥\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-list list-area" },
                _vm._l(_vm.trendings, function (project, ind) {
                  return _c(
                    "div",
                    {
                      key: ind,
                      staticClass: "list-item",
                      attrs: {
                        to: _vm.localePath(
                          "/" +
                            _vm.$getProjectCategory(project.type) +
                            "/" +
                            project.slug
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveRecent(project)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text-data" }, [
                        _c(
                          "div",
                          { staticClass: "image" },
                          [
                            _c("nuxt-img", {
                              attrs: {
                                src: _vm.$getImage(project.project_image),
                                alt: project.title + " " + project.token,
                                title: project.title,
                                quality: 80,
                                placeholder: "",
                                lazy: true,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _c("p", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(project.title) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "symbol" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  project.token !== undefined &&
                                    project.token !== null
                                    ? project.token
                                    : ""
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "rating" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              project.rank !== undefined &&
                                project.rank !== null &&
                                9999 > project.rank
                                ? "#" + project.rank
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.loading && _vm.totals.coins > 0
            ? _c("div", { staticClass: "list active" }, [
                _c("p", { staticClass: "header-desc" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.coins")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-list list-area" },
                  [
                    _vm._l(_vm.coinsShortList, function (project, ind) {
                      return _c(
                        "div",
                        {
                          key: ind,
                          staticClass: "list-item",
                          attrs: {
                            to: _vm.localePath(
                              "/" +
                                _vm.$getProjectCategory(project.type) +
                                "/" +
                                project.slug
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveRecent(project)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text-data" }, [
                            _c(
                              "div",
                              { staticClass: "image" },
                              [
                                _c("nuxt-img", {
                                  attrs: {
                                    src: _vm.$getImage(project.project_image),
                                    alt: project.title + " " + project.token,
                                    title: project.title,
                                    quality: 80,
                                    placeholder: "",
                                    lazy: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(project.title) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "symbol" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      project.token !== undefined &&
                                        project.token !== null
                                        ? project.token
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "rating" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  project.rank !== undefined &&
                                    project.rank !== null &&
                                    9999 > project.rank
                                    ? "#" + project.rank
                                    : ""
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.reveals.coins && _vm.totals.coins > _vm.filter.preview
                      ? _c(
                          "div",
                          {
                            staticClass: "reveal-search",
                            on: {
                              click: function ($event) {
                                return _vm.changeReveal("coins")
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("search.see_all_results")) +
                                  " (" +
                                  _vm._s(
                                    _vm.totals.coins > _vm.filter.step
                                      ? _vm.filter.step
                                      : _vm.totals.coins
                                  ) +
                                  ")\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading && _vm.totals.defi > 0
            ? _c("div", { staticClass: "list active" }, [
                _c("p", { staticClass: "header-desc" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.defi")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-list list-area" },
                  [
                    _vm._l(_vm.defiShortList, function (project, ind) {
                      return _c(
                        "div",
                        {
                          key: ind,
                          staticClass: "list-item",
                          attrs: {
                            to: _vm.localePath(
                              "/" +
                                _vm.$getProjectCategory(project.type) +
                                "/" +
                                project.slug
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveRecent(project)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text-data" }, [
                            _c(
                              "div",
                              { staticClass: "image" },
                              [
                                _c("nuxt-img", {
                                  attrs: {
                                    src: _vm.$getImage(project.project_image),
                                    alt: project.title + " " + project.token,
                                    title: project.title,
                                    quality: 80,
                                    placeholder: "",
                                    lazy: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(project.title) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "symbol" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      project.token !== undefined &&
                                        project.token !== null
                                        ? project.token
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "rating" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  project.rank !== undefined &&
                                    project.rank !== null &&
                                    9999 > project.rank
                                    ? "#" + project.rank
                                    : ""
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.reveals.defi && _vm.totals.defi > _vm.filter.preview
                      ? _c(
                          "div",
                          {
                            staticClass: "reveal-search",
                            on: {
                              click: function ($event) {
                                return _vm.changeReveal("defi")
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("search.see_all_results")) +
                                  " (" +
                                  _vm._s(
                                    _vm.totals.defi > _vm.filter.step
                                      ? _vm.filter.step
                                      : _vm.totals.defi
                                  ) +
                                  ")\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading && _vm.totals.exchanges > 0
            ? _c("div", { staticClass: "list active" }, [
                _c("p", { staticClass: "header-desc" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.exchanges")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-list list-area" },
                  [
                    _vm._l(_vm.exchangesShortList, function (project, ind) {
                      return _c(
                        "div",
                        {
                          key: ind,
                          staticClass: "list-item",
                          attrs: {
                            to: _vm.localePath(
                              "/" +
                                _vm.$getProjectCategory(project.type) +
                                "/" +
                                project.slug
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveRecent(project)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text-data" }, [
                            _c(
                              "div",
                              { staticClass: "image" },
                              [
                                _c("nuxt-img", {
                                  attrs: {
                                    src: _vm.$getImage(project.project_image),
                                    alt: project.title + " " + project.token,
                                    title: project.title,
                                    quality: 80,
                                    placeholder: "",
                                    lazy: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(project.title) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "symbol" }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "rating" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  project.rank !== undefined &&
                                    project.rank !== null &&
                                    9999 > project.rank
                                    ? "#" + project.rank
                                    : ""
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.reveals.exchanges &&
                    _vm.totals.exchanges > _vm.filter.preview
                      ? _c(
                          "div",
                          {
                            staticClass: "reveal-search",
                            on: {
                              click: function ($event) {
                                return _vm.changeReveal("exchanges")
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("search.see_all_results")) +
                                  " (" +
                                  _vm._s(
                                    _vm.totals.exchanges > _vm.filter.step
                                      ? _vm.filter.step
                                      : _vm.totals.exchanges
                                  ) +
                                  ")\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "list empty-list": true,
                active: !_vm.loading && _vm.emptyResult,
              },
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "h5" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("search.no_results_for")) +
                    '\n          "' +
                    _vm._s(_vm.filter.search) +
                    '"\n        '
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("search.couldnt_find")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("search.try_again")) +
                    "\n        "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: { "list empty-list": true, active: _vm.loading } },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "h5" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.loading")) +
                    "\n        "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: { list: true, active: _vm.recentSearches.length > 0 } },
            [
              _c("p", { staticClass: "header-desc" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("search.recent_searches")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-list searches-area" },
                _vm._l(_vm.recentSearches, function (link, ind) {
                  return _c(
                    "nuxt-link",
                    {
                      key: ind,
                      staticClass: "recent-search",
                      attrs: {
                        to: _vm.localePath(
                          "/" +
                            _vm.$getProjectCategory(link.type) +
                            "/" +
                            link.slug
                        ),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("nuxt-img", {
                            attrs: {
                              src: _vm.$getImage(link.project_image),
                              alt: link.title + " " + link.token,
                              title: link.title,
                              quality: 80,
                              placeholder: "",
                              lazy: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _c("p", [
                          _vm._v(
                            "\n                " +
                              _vm._s(link.title) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "symbol" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                link.token !== undefined &&
                                  link.token !== null &&
                                  link.type !== "exchange"
                                  ? link.token
                                  : ""
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-footer" }, [
          _c("div", { staticClass: "hint-area" }, [
            _c("div", { staticClass: "hotkey-style" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.esc")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("search.to_close")) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-search__button" }, [
      _c("i", { staticClass: "bx bx-search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-icon-area" }, [
      _c("div", { staticClass: "icon-circle" }, [
        _c("i", { staticClass: "bx bx-search" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-icon-area" }, [
      _c("div", { staticClass: "icon-circle" }, [
        _c("i", { staticClass: "bx bx-loader-alt loading" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }